var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-group-edit"}},[_c('vs-alert',{attrs:{"color":"danger","title":_vm.$t('administration.groupedit.not_found.title'),"active":_vm.isGroupNotFound},on:{"update:active":function($event){_vm.isGroupNotFound=$event}}},[_c('span',[_vm._v(_vm._s(_vm.$t('administration.groupedit.not_found.message', { 'groupId': _vm.$route.params.userId })))]),(_vm.$acl.check('admin'))?_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('administration.groupedit.not_found.verify')))]),_c('router-link',{staticClass:"text-inherit underline",attrs:{"to":{name:'user-group-list'}}},[_vm._v(" "+_vm._s(_vm.$t('administration.groupedit.not_found.all'))+" ")])],1):_vm._e()]),(_vm.group)?_c('vx-card',{staticClass:"pt-5 pb-5"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"userForm",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.saveForm(false))}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.groupedit.form.input.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h1',{staticClass:"flex flex-wrap items-center"},[_c('feather-icon',{staticClass:"mt-3",attrs:{"svgClasses":"w-16 h-16","icon":"LockIcon"}}),_c('vs-input',{staticClass:"w-3/12 ml-5",attrs:{"label":_vm.$t('administration.groupedit.form.input.name'),"placeholder":_vm.$t('administration.groupedit.form.input.name'),"name":"name"},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}}),_c('vs-button',{staticClass:"ml-auto mt-2",attrs:{"button":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('administration.userform.form.input.submit')))])],1),_vm._l((errors),function(error){return _c('span',{key:error},[_vm._v(_vm._s(error))])})]}}],null,true)})],1),_c('form',{ref:"userForm",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.saveForm(true))}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.groupedit.form.input.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vx-card',{staticClass:"mt-base",attrs:{"vs-icon":"lock","title":_vm.$t('administration.groupedit.form.admin.title'),"subtitle":_vm.$t('administration.groupedit.form.admin.alert'),"subtitle-color":"danger","no-shadow":"","card-border":""}},[_c('p',{staticClass:"mb-3"},[_c('vs-switch',{staticClass:"inline-flex",attrs:{"vs-value":"ROLE_ADMIN","color":"danger"},on:{"change":_vm.switchRoleEvent},model:{value:(_vm.group.roles),callback:function ($$v) {_vm.$set(_vm.group, "roles", $$v)},expression:"group.roles"}}),_c('span',{staticClass:"ml-3 align-top"},[_vm._v(_vm._s(_vm.$t('administration.groupedit.form.admin.role_admin')))])],1)]),_vm._l((errors),function(error){return _c('span',{key:error},[_vm._v(_vm._s(error))])})]}}],null,true)})],1)]}}],null,false,2745438650)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }