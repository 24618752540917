<template>
  <div id="user-group-edit">
    <vs-alert color="danger" :title="$t('administration.groupedit.not_found.title')" :active.sync="isGroupNotFound">
      <span>{{ $t('administration.groupedit.not_found.message', { 'groupId': $route.params.userId }) }}</span>
      <span v-if="$acl.check('admin')">
        <span>{{ $t('administration.groupedit.not_found.verify') }}</span>
        <router-link :to="{name:'user-group-list'}" class="text-inherit underline">
          {{ $t('administration.groupedit.not_found.all') }}
        </router-link>
      </span>
    </vs-alert>

    <vx-card v-if="group" class="pt-5 pb-5">
      <ValidationObserver v-slot="{ invalid, handleSubmit }">
        <form ref="userForm" @submit.prevent="handleSubmit(saveForm(false))">

          <ValidationProvider :name="$t('administration.groupedit.form.input.name')" rules="required" v-slot="{ errors }">
            <h1 class="flex flex-wrap items-center">
              <feather-icon class="mt-3" svgClasses="w-16 h-16" icon="LockIcon" />
              <vs-input class="w-3/12 ml-5" :label="$t('administration.groupedit.form.input.name')" :placeholder="$t('administration.groupedit.form.input.name')" v-model="group.name" name="name" />
              <vs-button button="submit" class="ml-auto mt-2" :disabled="invalid">{{ $t('administration.userform.form.input.submit') }}</vs-button>
            </h1>
            <span v-for="error in errors" :key="error">{{ error }}</span>
          </ValidationProvider>
        </form>

        <form ref="userForm" @submit.prevent="handleSubmit(saveForm(true))">
          <ValidationProvider :name="$t('administration.groupedit.form.input.name')" rules="required" v-slot="{ errors }">
            <vx-card
              class="mt-base"
              vs-icon="lock"
              :title="$t('administration.groupedit.form.admin.title')"
              :subtitle="$t('administration.groupedit.form.admin.alert')"
              subtitle-color="danger"
              no-shadow card-border>
              <p class="mb-3">
                <vs-switch @change="switchRoleEvent" class="inline-flex" v-model="group.roles" vs-value="ROLE_ADMIN" color="danger"/>
                <span class="ml-3 align-top">{{ $t('administration.groupedit.form.admin.role_admin') }}</span>
              </p>
            </vx-card>
            <span v-for="error in errors" :key="error">{{ error }}</span>
          </ValidationProvider>
        </form>
      </ValidationObserver>
    </vx-card>
  </div>
</template>

<script>
// Store Module
import moduleGroup from '@/store/group/moduleGroup'

export default {
  metaInfo: {
    title: 'Groups Edit'
  },
  data () {
    return {
      group: {
        roles: []
      },
      isGroupNotFound: false,
      lastSwitchedRole: ''
    }
  },
  methods: {
    switchRoleEvent (event) {
      this.lastSwitchedRole = event.target.value
    },
    fetchGroup (groupId) {
      this.$store.dispatch('group/fetchGroup', groupId)
        .then(res => {
          this.group = res.data
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.isGroupNotFound = true
            return
          }
          console.error(err) // eslint-disable-line no-console
        })
    },
    saveForm (isSwitch) {
      let action = 'group/addGroup'

      if (this.group.id) {
        action = 'group/updateGroup'
      } else if (isSwitch) {
        return
      }

      this.$store.dispatch(action, this.group)
        .then(() => {
          if (!isSwitch) {
            this.$router.push({name: 'user-group-list'})
          }
          this.$vs.notify({
            color: 'success',
            title: this.$t(`administration.groupedit.form.${isSwitch ? 'submit_role' : 'submit'}.success.notify.title`),
            text: this.$t(`administration.groupedit.form.${isSwitch ? 'submit_role' : 'submit'}.success.notify.text`, { name: this.group.name })
          })
        })
        .catch((error) => {
          this.$vs.notify({
            color: 'danger',
            title: this.$t('administration.groupedit.form.submit.error.notify.title'),
            text: this.$t('administration.groupedit.form.submit.error.notify.text', { error: error.response.data['hydra:description'] }),
            fixed: true
          })
        })
    }
  },
  created () {
    // Register Module UserManagement Module
    if (!moduleGroup.isRegistered) {
      this.$store.registerModule('group', moduleGroup)
      moduleGroup.isRegistered = true
    }
    if (typeof this.$route.params.groupId !== 'undefined') {
      this.fetchGroup(this.$route.params.groupId)
    }
  }
}
</script>

<style lang="scss" scoped>
  h1 label {

  }
</style>
